<template>
  <div
    class="filter-main__wrapper"
    :class="{ 'is-hidden': isHidden }"
  >
    <div
      ref="scrollerRef"
      class="filter-main"
      @scroll="handleScroll"
    >
      <div
        class="filter-main__inner"
      >
        <div
          v-for="tab of prettified"
          :key="tab.id"
          :class="{ 'is-active': activeId === tab.id }"
          class="filter-main__item"
          @click="handleSelection(tab, false)"
          @keypress="handleSelection(tab, false)"
        >
          <span
            v-if="counts && Object.keys(counts)?.length > 0"
            class="filter-main__item-count"
          >({{ counts[tab.name] }})</span>
          {{ tab.name }}
        </div>
      </div>
    </div>

    <transition name="fade-in">
      <div
        v-if="clientDeviceStore.isMobile && !scrolledToEnd"
        class="filter-main__fade"
      />
    </transition>
  </div>
</template>

<script setup>
import { useWindowSize } from '@vueuse/core';

const emit = defineEmits(['set-filter-types', 'set-active-main-filter-name']);

const props = defineProps({
    tabs: {
        type: Object,
        default: () => {},
    },
    counts: {
        type: Object,
        default: () => {},
    },
    isHidden: {
        type: Boolean,
        default: false,
    },
});

const prettified = props.tabs?.map((tab) => ({
    name: tab.name,
    id: tab._uid,
    types: tab.types,
    initiallySelected: tab.is_preselected === true,
}));

/*
    Handling selection
*/
const activeId = ref(null);
const initiallySelected = prettified.find((item) => item.initiallySelected);
activeId.value = initiallySelected?.id;

const handleSelection = (item, isInitial = true) => {
    activeId.value = item.id;
    emit('set-filter-types', { values: item.types, isInitial });
    emit('set-active-main-filter-name', item.name);
};

/*
    Handle Fade out
*/
const scrollerRef = ref(null);
const isOverflowing = ref(false);
const clientDeviceStore = useClientDeviceStore();
const checkOverflow = () => {
    isOverflowing.value = scrollerRef.value.scrollWidth - 30 > scrollerRef.value.clientWidth;
};

const { width } = useWindowSize({
    includeScrollbar: false,
});

const showFadeOut = ref(false);
const setFadeOut = () => {
    showFadeOut.value = scrollerRef.value.scrollLeft < 10;
};

watch(() => width.value, () => {
    checkOverflow();
    if (isOverflowing.value) {
        setFadeOut();
    }
});

onMounted(() => {
    checkOverflow();
    setFadeOut();
});

const scrolledToEnd = ref(false);
const handleScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = scrollerRef.value;
    scrolledToEnd.value = scrollLeft + clientWidth >= scrollWidth - 60;
};
</script>

<style lang="scss" scoped>
$transitionDuration: 0.1s;
.filter-main__wrapper {
    @include grid-columns(8, 11);

    position: relative;
    display: flex;
    justify-content: flex-end;

    @include tablet-portrait {
        @include grid-full;
        overflow: hidden;
        width: 100vw;
    }

    .fade-in-enter-active,
    .fade-in-leave-active {
        transition: opacity 0.1s ease-in-out;
    }

    &.is-hidden {
        display: none;
    }
}

.filter-main {
    display: flex;
    max-width: 100%;
    flex-wrap: nowrap;
    white-space: nowrap;

    @include tablet-portrait {
        @include remove-scrollbars();
        overflow-x: auto;
        white-space: nowrap;
    }
}

.filter-main__inner {
    display: flex;
    flex-wrap: nowrap;
    padding: 10px;
    border-radius: 50px;
    background-color: $C_GREEN_BRIGHT;
    column-gap: 2px;
    white-space: nowrap;

    @include tablet-portrait {
        position: relative;
        margin-right: var(--page-padding);
        margin-left: var(--page-padding);
    }

    .is-recommendation & {
        background-color: $C_WHITE;
    }
}

.filter-main__item {
    @include typo-size('nav');
    @include typo-font('regular');

    @include fluid('padding-top', 7px, 10px);
    @include fluid('padding-bottom', 7px, 10px);
    @include fluid('padding-left', 18px, 20px);
    @include fluid('padding-right', 18px, 20px);

    border-radius: 50px;
    color: $C_PRIMARY;
    cursor: pointer;

    transition: background-color $transitionDuration, color $transitionDuration ease-in-out;

    &.is-active {
        background-color: $C_SECONDARY;
        color: $C_WHITE;
    }
}

.filter-main__fade {
    position: absolute;
    top: 0;
    right: 0;

    width: calc(var(--page-padding) * 2);
    height: 100%;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    pointer-events: none;
}

.filter-main__item-count {
    color: $C_GREEN_DARK;

    .is-active & {
        color: $C_WHITE;
    }
}
</style>
